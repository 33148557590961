import React from "react";
import Header from "components/Header/Header";
import Top from "components/Top/Top";
import Main from "components/Main/Main";
import TabBar from "components/TabBar/TabBar";
import { log } from "console";

const Home = () => {
  const getCookieValue = (cookieName: string) => {
    var name = cookieName + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var cookieArray = decodedCookie.split(";");

    for (var i = 0; i < cookieArray.length; i++) {
      var cookie = cookieArray[i].trim();
      if (cookie.indexOf(name) === 0) {
        return cookie.substring(name.length, cookie.length);
      }
    }
    return null;
  };

  const downLoadApp = () => {
    const fbcValue = getCookieValue("_fbc") || "";
    const fbpValue = getCookieValue("_fbp") || "";
    const urlParams = new URLSearchParams(window.location.search);
    // const p0 = urlParams.get("p0");
    // const p1 = urlParams.get("p1");
    // const p2 = urlParams.get("p2");
    // const p3 = urlParams.get("p3");
    // const p4 = urlParams.get("p4");
    // const p5 = urlParams.get("p5");
    // const p6 = urlParams.get("p6");

    const utm_campaign = urlParams.get("utm_campaign");
    const utm_ad = urlParams.get("utm_ad");
    const utm_adset = urlParams.get("utm_adset");
    const fbclid = urlParams.get("fbclid");
    const media = urlParams.get("media");
    const web = urlParams.get("web");
    const webDecode = window.atob(web);
    
    const webSrc = sessionStorage.getItem("src");
    const encodeSrc = window.btoa(webSrc);
    // console.log("webSrc=", webSrc);

    let src = `https://d387s86apzifz3.cloudfront.net/Playing.apk`;
    if (web) {
      src = `${webDecode}?af_js_web=true&af_ss_ver=2_7_3&deep_link_value=${encodeSrc}&af_ss_ui=true`;
    }
    if (fbclid) {
      src = `${webDecode}?af_js_web=true&af_ss_ver=2_7_3&pid=${media}&c=${utm_campaign}&af_ad=${utm_ad}&af_adset=${utm_adset}&deep_link_value=${encodeSrc}&af_ss_ui=true`;
    }
    // console.log("src=",src);
    
    const link = document.createElement("a");
    link.href = src;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div>
      <Header downLoadApp={downLoadApp} />
      <Top downLoadApp={downLoadApp} />
      <Main downLoadApp={downLoadApp} />
      <TabBar downLoadApp={downLoadApp} />
    </div>
  );
};

export default Home;
